<template>
    <div class="footer uk-padding">

        <div class="uk-grid-small uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center uk-text-center" uk-grid>
            <div class="uk-text-center">
                <span>

                    <router-link v-if="$route.name !== 'main'" :to="'/'+$i18n.locale+'/'">
                        <img alt="Logo" class="logo" :src="require('../assets/'+getMode+'/logo.svg')"/>
                    </router-link>

                    <a v-else href="#app" uk-scroll>
                        <img alt="Logo" class="logo" :src="require('../assets/'+getMode+'/logo.svg')"/>
                    </a>

                </span>
                <div class="copyright">
                    © <span>{{ currentYear }}</span>
                </div>
            </div>

            <div>
                <div
                    :class="'uk-grid-match uk-flex uk-flex-center uk-flex-middle ' + (isDeviceMobile ? 'uk-grid-small' : 'uk-grid')"
                    uk-grid>
                    <div v-for="link in links" :key="link" class="link-item ">
                        <LinkObject :to="(link === 'aboutUs'?  $t(link+'Link') : $t(link))">

                            <Button v-if="link === 'contact' && getMode!=='cancelcancer' && getMode!=='camcore'"
                                    :uk-tooltip="'title:'+$t('freeOffer')"
                                    mode="success">
                                {{ $t(link) }}
                            </Button>
                            <Button v-else-if="link === 'donate' && getMode ==='cancelcancer'"
                                    mode="success">
                                {{ $t(link) }}
                            </Button>
                            <span v-else>
                                 {{ $t(link) }}
                            </span>

                        </LinkObject>
                    </div>
                </div>
            </div>

            <div class="uk-margin-top">
                <SocialMediaButtons :iconRatio="1.0"/>
            </div>

            <div>
                <div class="uk-grid-small uk-grid-match uk-flex uk-flex-center" uk-grid>
                    <div>
                        <LinkObject :to="$t('sitenotice')" class="uk-link-muted">
                            {{ $t('siteNotice') }}
                        </LinkObject>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import SocialMediaButtons from "./SocialMediaButtons";
import {isMobileOnly} from 'mobile-device-detect';
import LinkObject from "./LinkObject";
import Button from "@/components/Button.vue";

export default {
    name: 'Footer',
    components: {Button, LinkObject, SocialMediaButtons},
    props: {
        links: Array
    },
    data() {
        return {
            isDeviceMobile: isMobileOnly,
            currentYear: "",
        };
    },
    created() {
        let date = new Date();
        this.currentYear = date.getFullYear();
    },
}

</script>


<style lang="scss" scoped>

.footer {
    background-color: $alternative-color;
}

.link-item {
    font-size: 16px;
}

.adjust-links-distance {
    margin-top: 9px;
}

.adjust-grid-distance {
    margin-top: 15px !important;
}

.adjust-socials-distance {
    padding-top: 33px;
}

.copyright {
    margin-top: 5px;
}

.uk-width-smedium {
    width: 240px;
}

.sitenotice {
    margin-top: 20px;
}

@media (max-width: 375px) {
    .uk-padding {
        padding: 20px !important;
    }
}

</style>
