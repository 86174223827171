<template>
    <div class="background-section">
        <Seo
            :canonical="'/' + $t('inDevelopment').toLowerCase()"
            :title="$t('inDevelopment')" />

        <div class="uk-grid-collapse uk-grid-match" uk-grid>
            <div class="uk-width-1-1 video-struct init-struct first-video">
                <div class="uk-cover-container uk-height-1-1 uk-animation-fade ">
                    <img
                        id="video-main"
                        class="video-main"
                        uk-cover
                        :src="
                            require('../assets/' +
                                getMode +
                                '/vegankilling/background.png')
                        " />

                    <div class="uk-position-center uk-text-center">
                        <img
                            class="title-img"
                            :src="
                                require('../assets/' +
                                    getMode +
                                    '/vegankilling/title.svg')
                            "
                            uk-img />

                        <div class="uk-visible@l">
                        <div class="site-width divided">
                            <div class="uk-margin-medium-top" />
                            <div
                                class="uk-grid uk-grid-divider uk-flex-center info-title"
                                uk-grid>
                                <div>Road Trip Serie</div>
                                <div>Genremix Actionkomödie</div>
                                <div>6 x 25 min. Staffel</div>
                                <div>Drehstart: Q4 2025</div>
                            </div>

                            <div class="uk-margin-top info-text">
                                Ein Auftragskiller-Duo entdeckt den veganen
                                Lebensstil für sich und beschließt zu kündigen.
                                Mit ihrem Boss als Geisel begeben sie sich auf
                                einen Road-Trip voller Genre-Mixes, der das Trio
                                an die Grenzen ihrer Fähigkeiten bringt und ihre
                                Beziehungen auf die bisher härteste Probe
                                stellt.
                            </div>
                        </div>

                        <div class="uk-margin-medium-top" />
                        <a     href="#reservation"
                               uk-scroll="offset: 15">
                        <Button
                            mode="default"
                            is-large
                        >
                            Jetzt Rezeptbuch anfragen
                        </Button></a>

                        <div class="uk-margin-top">
                            <div class="dark">Gefördert von:</div>
                            <img
                                class="mbb-img"
                                :src="
                                    require('../assets/' +
                                        getMode +
                                        '/vegankilling/mbb.svg')
                                "
                                uk-img />
                        </div>
                    </div>
                    </div>
                    <div
                        class="uk-position-bottom uk-text-center uk-margin-bottom next-item"
                        uk-scrollspy="cls: uk-animation-slide-top-small; delay: 1000;">
                        <a
                            class="uk-link-muted uk-link-white"
                            href="#next"
                            uk-scroll="offset: 81">
                            <span uk-icon="icon: chevron-down; ratio: 2"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <span id="next" />

        <div class="site-width divided uk-hidden@l uk-text-center">

            <div class="">
                <div class="site-width divided">
                    <div class="uk-margin-medium-top" />
                    <div
                        class="uk-grid uk-grid-divider uk-flex-center info-title"
                        uk-grid>
                        <div>Road Trip Serie</div>
                        <div>Genremix Actionkomödie</div>
                        <div>6 x 25 min. Staffel</div>
                        <div>Drehstart: Q4 2025</div>
                    </div>

                    <div class="uk-margin-top info-text">
                        Ein Auftragskiller-Duo entdeckt den veganen
                        Lebensstil für sich und beschließt zu kündigen.
                        Mit ihrem Boss als Geisel begeben sie sich auf
                        einen Road-Trip voller Genre-Mixes, der das Trio
                        an die Grenzen ihrer Fähigkeiten bringt und ihre
                        Beziehungen auf die bisher härteste Probe
                        stellt.
                    </div>
                </div>

                <div class="uk-margin-medium-top" />
                <a     href="#reservation"
                       uk-scroll="offset: 15">
                    <Button
                        mode="default"
                        is-large
                    >
                        Jetzt Rezeptbuch anfragen
                    </Button></a>

                <div class="uk-margin-top">
                    <div class="dark">Gefördert von:</div>
                    <img
                        class="mbb-img"
                        :src="
                                    require('../assets/' +
                                        getMode +
                                        '/vegankilling/mbb.svg')
                                "
                        uk-img />
                </div>
            </div>
        </div>



        <div class="site-width divided">
            <div class="section-distance-new" />

            <div class="init-title uk-text-center bangers">
                Das phil ó Sophie Menü
            </div>

            <div class="uk-margin-top" />
            <div class="info-text uk-text-center">
                Handpulled Action fusioniert mit frischen Emotionen.<br />
                Für die Zubereitung unserer Gerichte verwenden wir
                ausschließlich sorgfältig ausgesuchte und authentische Zutaten.
                Handgemachte Action und origineller Humor zählen zu unseren
                bewährten Gewürzen. Reale Kulissen und mitreißende Musik
                begleiten das Menü vom ersten bis zum letzten Gang. Zum Schluss
                sorgt eine Prise Fantasie für die Abrundung, voilá.
            </div>

            <div class="uk-margin-large-top" />
            <div
                class="uk-grid-large info-text uk-child-width-1-2@m uk-child-width-1-1"
                uk-grid>
                <div>
                    <div class="bangers child-title">Kochteam</div>
                    <div>
                        <span class="weight">Showrunner:</span> Daniel Littau,
                        Andreas Olenberg, Manuel Mohnke, Guido Broscheit<br />
                        <span class="weight"> Drehbuch:</span> Daniel Littau,
                        Andreas Olenberg, Manuel Mohnke<br />
                        <span class="weight"> Creative Consultant:</span>
                        Patrick Simon<br />
                        <span class="weight"> Koproduzent:</span> Dropkick
                        Pictures<br />
                        <span class="weight"> Förderungen:</span> Medienboard
                        BerlinBrandenburg<br />
                        <span class="weight"> Drehstart:</span> Q4 2025<br />
                    </div>
                </div>

                <div>
                    <div class="bangers child-title">Serviervorschlag</div>
                    <div>
                        Erlebe eine kulinarische Reise, die als
                        <span class="weight">6-Gänge Menü</span> serviert wird.
                        Für den Verzehr sind
                        <span class="weight">25 Minuten pro Gericht</span>
                        eingeplant. Das Besondere: Jeder Gang hat seine
                        <span class="weight">eigene Charakteristik</span>,
                        weshalb sich unsere Chefs bewusst für eine mehrteilige
                        Komposition entschieden haben.
                        <span class="weight">Action-Comedy</span> gilt dabei als
                        Grundzutat und ist das übergreifende Genre. Harmonisch
                        aufeinander abgestimmte Aromen bringen somit am Ende des
                        Menüs ein <span class="weight">150 Minuten</span>
                        langes Geschmackserlebnis hervor.
                    </div>
                </div>
            </div>

            <div class="uk-margin-large-top" />
            <div
                class="uk-grid-small uk-child-width-1-3@m uk-child-width-1-1 uk-flex-center uk-text-center"
                uk-grid>
                <div>
                    <img
                        uk-toggle="target: #modal-media-1"
                        class="preview-img cursor-pointer"
                        :src="
                            require('../assets/' +
                                getMode +
                                '/vegankilling/1.svg')
                        "
                        uk-img />

                    <div id="modal-media-1" class="uk-flex-top" uk-modal>
                        <div
                            class="uk-modal-dialog background-section uk-width-auto uk-margin-auto-vertical uk-padding-remove">
                            <button
                                class="uk-modal-close-outside"
                                type="button"
                                uk-close></button>
                            <img
                                class="uk-height-large"
                                :src="
                                    require('../assets/' +
                                        getMode +
                                        '/vegankilling/1.svg')
                                "
                                uk-img />
                        </div>
                    </div>
                </div>
                <div>
                    <img
                        uk-toggle="target: #modal-media-2"
                        class="preview-img cursor-pointer"
                        :src="
                            require('../assets/' +
                                getMode +
                                '/vegankilling/2.svg')
                        "
                        uk-img />

                    <div id="modal-media-2" class="uk-flex-top" uk-modal>
                        <div
                            class="uk-modal-dialog background-section uk-width-auto uk-margin-auto-vertical uk-padding-remove">
                            <button
                                class="uk-modal-close-outside"
                                type="button"
                                uk-close></button>
                            <img
                                class="uk-height-large"
                                :src="
                                    require('../assets/' +
                                        getMode +
                                        '/vegankilling/2.svg')
                                "
                                uk-img />
                        </div>
                    </div>
                </div>
                <div>
                    <img
                        uk-toggle="target: #modal-media-3"
                        class="preview-img cursor-pointer"
                        :src="
                            require('../assets/' +
                                getMode +
                                '/vegankilling/3.svg')
                        "
                        uk-img />

                    <div id="modal-media-3" class="uk-flex-top" uk-modal>
                        <div
                            class="uk-modal-dialog background-section uk-width-auto uk-margin-auto-vertical uk-padding-remove">
                            <button
                                class="uk-modal-close-outside"
                                type="button"
                                uk-close></button>
                            <img
                                class="uk-height-large"
                                :src="
                                    require('../assets/' +
                                        getMode +
                                        '/vegankilling/3.svg')
                                "
                                uk-img />
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-distance-new" />

            <div class="init-title uk-text-center bangers">Hauptgerichte</div>

            <div class="uk-margin-top" />

            <div
                class="uk-grid-large info-text uk-child-width-1-3@m uk-child-width-1-1"
                uk-grid>
                <div>
                    <span class="weight">PHIL (33)</span> entwickelte sich als
                    Sohn ermordeter Bestatter vom Waisen zum kaltblütigen
                    Auftragskiller. Er ist hin- und hergerissen zwischen dem von
                    ihm angeeigneten luxuriösen Killerleben und dem Wunsch nach
                    einer zivilen Zukunft an der Seite seiner plötzlich zum
                    Veganismus konvertierten Freundin Sophie.
                </div>

                <div>
                    <span class="weight">SOPHIE (33)</span> ist die eiskalte
                    Hälfte des gefürchteten Auftragskiller-Duos "Die Phil ó
                    Sophie Killer“. Sie durchlebt einen radikalen Sinneswandel
                    hin zum Veganismus und Umweltaktivismus, der nicht nur ihren
                    Killerberuf, sondern auch die Beziehung zu ihrem Partner
                    Phil auf die bisher härteste Probe stellt.
                </div>

                <div>
                    <span class="weight">BRUNO (55)</span> der skrupellose Chef
                    der Agentur für Auftragskiller. Trotz der aufkommenden
                    ethischen Bedenken seiner beiden Top-Killer, möchte er Phil
                    und Sophie nicht gehen lassen. Mit allen Mitteln versucht er
                    sein Imperium, die Beziehung zu seinem Ziehsohn Phil und
                    seinen eigenen Kopf zu retten.
                </div>
            </div>

            <div class="section-distance-new" />

            <div class="init-title uk-text-center bangers">Synopsis</div>

            <div class="uk-margin-top" />

            <div
                class="uk-grid-large info-text uk-child-width-1-2@m uk-child-width-1-1"
                uk-grid>
                <div>
                    Morgens Kaffee mit Sojamilch trinken und abends Menschen
                    beseitigen? Das passt einfach nicht mehr zusammen. Mit
                    diesem Gedanken müssen sich unsere beiden Protagonisten
                    <span class="weight">PHIL (33)</span> und
                    <span class="weight">SOPHIE (33)</span> seit kurzem
                    herum-schlagen. Auch bekannt als "Die Phil ò Sophie Killer"
                    schalten sie mühelos Tag für Tag hochkriminelle Gangster
                    aus. Doch auch für Auftragsmörder wie diese existieren
                    Regeln, die sie als ungeschriebene Gesetze des Berufsethos
                    verstehen: Keine Kinder, keine Tiere und keine Sonntage.<br /><br />Trotz
                    ihres vollen Terminkalenders führt das liierte Killer-Duo
                    eine gesunde Wok-Life Balance in einer gemütlichen Vorstadt.
                    Sophie engagiert sich nebenbei ehrenamtlich bei einer
                    Umweltorganisation, während sich Phil mit Hilfe von YouTube
                    Tutorials als Koch ausprobiert. Ihr Boss
                    <span class="weight">BRUNO (55)</span> versucht
                    währenddessen sein Baby, die Agentur für Auftragskiller, mit
                    allen Mitteln am Leben zu halten – auch wenn er dafür den
                    über Jahrzehnte eingehaltenen Agenturcodex missachten und
                    sein eigenes Lebens riskieren muss. Phil und Sophie spüren,
                    dass sich innerhalb der Agentur etwas verändert. Doch sie
                    behalten ihre Zweifel erstmal für sich.<br /><br />Der immer
                    wieder missglückte Heiratsantrag von Phil, ein durch Sophies
                    Projektil zerfetzter Papagei und ein verlockendes
                    Stellenangebot einer Umweltorganisation sorgen endgültig für
                    den Sinneswandel. Ist es vielleicht an der Zeit über einen
                    Jobwechsel nachzudenken? Bruno hält nicht viel von dem
                    plötzlichen Meinungsumschwung. So einfach wird er seine
                    Cashcows nicht ziehen lassen.
                </div>

                <div>
                    Auch muss sich Bruno gestehen, dass ihm die drohende
                    Abspaltung seines Ziehsohnes Phil weh tun würde. Um Zeit zu
                    gewinnen zwingt er das Killer-Duo, einen letzten großen
                    Auftrag auszuführen. In einem Moment der Schwäche gelingt es
                    Phil und Sophie, den verwundeten Bruno gefangen zu nehmen
                    und zu fliehen. Auf der Flucht vor Kopfgeldjägern, wütenden
                    Kunden und umweltschützenden Kannibalen müssen sie nicht nur
                    ständig ihre alten Killer-Fähigkeiten aufbieten, sondern
                    auch ihre eigenen moralischen Prinzipien immer wieder
                    überdenken. So kommt es, dass sich das ungleiche Trio in
                    gestohlenen Autos und abgelegenen Motels vor extremen
                    Umständen schützen muss. Zeit zum Verschnaufen bleibt keine.
                    Sie lernen aber auch die einfachen Freuden eines Road Trips
                    wie spontanes Camping, gemeinsame Lagerfeuer und den Genuss
                    regionaler Delikatessen zu schätzen.<br /><br />In einer
                    letzten großen Konfrontation müssen die Phil ò Sophie Killer
                    entscheiden, ob sie ihre ethischen Ideale aufgeben und für
                    immer zu skrupellosen Killern werden – oder ob sie stark
                    genug sind, um der Gewalt ein für alle Mal den Rücken zu
                    kehren. Ein halsbrecherischer Road Trip, der Phil und
                    Sophies Beziehung auf ihre bisher härteste Probe stellt und
                    die beiden ans Limit ihrer Profikiller-Fähigkeiten bringt.

                    <br /><br /><br />
                    Inhaltsstoffe: 219kg Menschenfleisch, 1.435kg PKW, 2.500km
                    Autobahn, kann Spuren von Blut enthalten
                </div>
            </div>

            <div class="section-distance-new" />
            <div class="uk-text-center">
                <img
                    uk-toggle="target: #modal-media-4"
                    class=" cursor-pointer"
                    :src="
                        require('../assets/' + getMode + '/vegankilling/4.svg')
                    "
                    uk-img />

                <div id="modal-media-4" class="uk-flex-top" uk-modal>
                    <div
                        class="uk-modal-dialog background-section uk-width-auto uk-margin-auto-vertical uk-padding-remove">
                        <button
                            class="uk-modal-close-outside"
                            type="button"
                            uk-close></button>
                        <img
                            class="uk-height-large"
                            :src="
                                require('../assets/' +
                                    getMode +
                                    '/vegankilling/4.svg')
                            "
                            uk-img />
                    </div>
                </div>
            </div>

            <div class="section-distance-new" />

            <div class="init-title uk-text-center bangers">Chef´s Note</div>

            <div class="uk-margin-top" />

            <div
                class="uk-grid-large info-text uk-child-width-1-2@m uk-child-width-1-1"
                uk-grid>
                <div>
                    "Vegan Killing" dreht sich um eine der größten
                    existenziellen Fragen unserer Spezies: Wann ist es
                    vertretbar Leben zu nehmen? Gibt es Situationen, in denen
                    der Zweck die Mittel heiligt und Gewalt legitim wird – sei
                    es gegen Tiere oder gar Mitmenschen? Mit dieser provokanten
                    Prämisse wollen wir keine einfachen Antworten liefern,
                    sondern vielschichtige Denkanstöße und Perspektiven
                    eröffnen. Wir laden das Publikum ein, tief in die finsteren
                    Abgründe der menschlichen Psyche einzutauchen, aber auch die
                    hellen, leuchtenden Seiten unserer Seele zu entdecken.
                    <br /><br />Auf den ersten Blick scheint "Vegan Killing"
                    eine aberwitzige, überdrehte Action-Komödie mit skurrilen
                    Charakteren und haarsträubenden Wendungen zu sein. Doch
                    unter der schillernden Oberfläche verbirgt sich ein
                    berührender Kern aus familiären Themen wie
                    Vater-Sohn-Beziehungen, unerfüllte Sehnsüchte, Verrat und
                    Loyalität. Mit einem Augenzwinkern und viel Selbstironie
                    möchten wir das Spiel mit Klischees und überzogenen
                    Archetypen genüsslich zelebrieren und gleichzeitig die
                    Figuren Schritt für Schritt aus ihren festen Rollen
                    ausbrechen lassen. Die Fassaden bröckeln, hinter den coolen
                    Killern lüftet sich der Blick auf ihre verletzlichen Seelen.
                    Immer mehr verschwimmen die Grenzen zwischen Gut und Böse,
                    zwischen Gewinnern und Verlierern. Letztendlich wandelt sich
                    der scheinbare Action-Krawall zu einer poetischen Studie
                    über die Flüchtigkeit des Daseins und die Kraft
                    bedingungsloser Zuneigung.
                </div>

                <div>
                    Durch die Übersteigerung der Action-Motive und einen fast
                    schon tragikkomischen Zug in der Inszenierung wollen wir das
                    Publikum zunächst für einen größtmöglichen
                    Unterhaltungsfaktor ködern. Inspiriert von den großen
                    Leinwandlegenden wie Stallone, Schwarzenegger und Jackie
                    Chan, soll "Vegan Killing" eine mitreißende Hommage an die
                    Kunst der handgemachten Action werden - mit ausgeklügelten
                    Choreographien und spektakulären Stunts von echten Menschen
                    statt aufgesetzter CGI-Effekte. Dennoch möchten wir nichts
                    kopieren, sondern unseren ganz eigenen Stilmix kreieren. Das
                    äußerst interessante dabei ist es zu beobachten wie Phil und
                    Sophie aufgrund ihrer neuen Prinzipien in den Nahkampf-Modus
                    wechseln müssen, um niemanden ernsthaft zu verletzen oder
                    gar umzubringen. <br /><br />Der ständige Wechsel zwischen
                    erfrischender Komik und tiefsinniger Emotion empfinden wir
                    als besonderen Reiz. Die Aufteilung in mehrere Episoden ist
                    angelehnt an die "Fünf Phasen der Trauer". Die Figuren
                    durchleben unterschiedliche Gefühlsphasen, was sich auch in
                    wechselnden Genre-Schwerpunkten widerspiegelt. Jede Episode
                    erhält so eine eigene Note in diesem amüsanten Genremix. Mit
                    dieser Mischung aus lebensfroher Leichtigkeit und
                    philosophischer Schwere möchten wir das Publikum überraschen
                    und berühren. Letztlich ist "Vegan Killing" eine leichte
                    Kost über Freundschaft und Familie als die essenziellsten
                    Werte des Lebens. Eine Serie, die einfach Spaß machen und
                    gute Laune bereiten soll – Bon Appetit.
                </div>
            </div>
            <span id="reservation" />

            <div class="section-distance-new" />

            <div class="init-title uk-text-center bangers">
                Tisch reservieren
            </div>

            <div class="uk-margin-top" />

            <div class="uk-text-center info-title numb">
                <a href="mailto:reservierung@vegankilling.de">
                    reservierung@vegankilling.de
                </a>
            </div>
            <div class="uk-text-center info-title numb">
                <a href="tel:015784489587"> 0157 844 89 587 </a>
            </div>

            <div class="end" />
        </div>
    </div>
</template>

<script>
import Seo from '../components/Seo';
import Button from '@/components/Button.vue';

export default {
    name: 'InDevelopment',
    components: {
        Button,
        Seo,
    },
};
</script>

<style lang="scss" scoped>
.title-img {
    height: 160px;
}

.preview-img {
    height: 200px;
}






.dark {
    color: #000;
    font-weight: 500;

    margin-bottom: 5px;
}

.info-title {
    font-weight: 500;
    font-size: 18px;
}

.info-text {
    font-size: 18px;
    text-align: left;
}

.end {
    padding-bottom: 100px;
}

.background-section {
    background: #932924;
    color: #fff;
}

.init-title {
    font-size: 60px;
}

.bangers {
    font-family: bangers;
}

.weight {
    font-weight: 500;
}

.numb {
    font-size: 24px;
}

.child-title {
    font-size: 40px;
}

.mbb-img {
    max-height: 50px;
}
</style>
